// eslint-disable-next-line max-classes-per-file
import { ShareManagementValue } from '~/app/core/utils/share-details-utils/share-details-utils';
import { Benchmark } from '~/app/shared/enums/benchmark.enum';
import { CalendarPerformanceType } from '~/app/shared/enums/calendar-performance-type.enum';
import { ContributionModeType } from '~/app/shared/enums/contribution-mode-type.enum';
import { ShareViewModeValue } from '~/app/shared/enums/share-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import {
    ActiveRiskValueRequest,
    NotationValueRequest,
    RiskIndicatorValueRequest,
} from '~/app/shared/interfaces/RiskIndicator';
import { ComparedEntity } from '~/app/shared/types/compared-entity.type';

export class ChangeViewModeAction {
    static readonly type = '[ShareDetails] Change view mode';

    constructor(public viewMode: ViewModeType) {}
}

export class GetShareDetailsAction {
    static readonly type = '[ShareDetails] Get';

    constructor(public id: number, public shareParams: ShareManagementValue | null = null) {}
}

export class InitShareDetailsAction {
    static readonly type = '[ShareDetail] Init ShareDetail state';
}

export class GetSimilarSharesAction {
    static readonly type = '[ShareDetail] Similar';

    constructor(public id: number, public reset: boolean = false) {}
}

export class GetOtherSharesAction {
    static readonly type = '[ShareDetail] Other';

    constructor(public id: number, public reset: boolean = false) {}
}

export class GetIndicatorsAction {
    static readonly type = '[ShareDetail] Get indicators of shares';

    constructor(public sharesId: Array<number>, public nameField: string) {}
}

export class GetCompatibleCategoriesAction {
    static readonly type = '[ShareDetail] Compatible Categories';

    constructor(public id: number) {}
}

export class GetNotationsValueForShareDetailAction {
    static readonly type = '[ShareDetail] Get notations value';

    constructor(
        public shareIds: number[],
        public categoryIds: number[],
        public notationsParam: Array<NotationValueRequest>,
        public endDate: string | null = null,
    ) {}
}

export class GetRiskIndicatorsValueForShareDetailAction {
    static readonly type = '[ShareDetail] Get risk indicators value';

    constructor(
        public shareIds: number[],
        public categoryIds: number[],
        public riskIndicatorsParam: Array<RiskIndicatorValueRequest>,
        public changeIsLoading: boolean,
        public endDate: string | null = null,
    ) {}
}

export class GetActiveRisksValueForShareDetailAction {
    static readonly type = '[ShareDetail] Get active risks value';

    constructor(
        public shareIds: number[],
        public categoryIds: number[],
        public activeRisksParam: Array<ActiveRiskValueRequest>,
        public endDate: string | null = null,
    ) {}
}

export class ResetViewModeDisplayAction {
    static readonly type = '[ShareDetail] Reset view mode display';
}

export class UpdateShareDetailsRollingPeriodParams {
    static readonly type = '[ShareDetail] Update rolling period params';

    constructor(public period: string, public endDate: string, public riskIndicatorsViewMode: ViewModeType) {}
}

export class UpdateShareDetailsCalendarPerfParams {
    static readonly type = '[ShareDetail] Update calendar performance params';

    constructor(public type: CalendarPerformanceType) {}
}

export class UpdateShareDetailsMarketCycleParamsAction {
    static readonly type = '[ShareDetail] Update market cycle params';

    constructor(public benchmark: Benchmark, public contributionMode: ContributionModeType) {}
}

export class SetComparedEntitiesAction {
    static readonly type = '[ShareDetail] Set compared entities';

    constructor(public entities: ComparedEntity[]) {}
}

export class SetViewModeDisplayValuesAction {
    static readonly type = '[ShareDetail] Set viewMode display values';

    constructor(public display: ShareViewModeValue[]) {}
}
